import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/LoginStyle.css';

const transitionLabel = () => {
  const labels = document.querySelectorAll('.form-control-login label');

  labels.forEach(label => {
    label.innerHTML = label.innerText
      .split('')
      .map((letter, idx) => `<span style="transition-delay:${idx * 50}ms">${letter}</span>`)
      .join('');
  });
};

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(''); 
  const navigate = useNavigate();

  useEffect(() => {
    transitionLabel();
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError(''); 

    try {
      const response = await fetch('http://45.55.197.98:5000/api/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.Estado === 0) {
          setError('Tu cuenta está desactivada. No puedes iniciar sesión.');
        } else {
          localStorage.setItem('token', data.token);
          navigate('/app');
        }
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Error al iniciar sesión');
      }
    } catch (error) {
      setError('Error en la solicitud de inicio de sesión');
    }
  };

  return (
    <div className='body-login'>
      <div className="container-login">
        <div className="logo-container">
          <img 
            src="https://www.centralshop.com.py/_next/static/media/centralshoplogo.d08fd0e3.svg" 
            alt="Logo" 
            style={{ width: '150px' }} 
          />
        </div>
        <form onSubmit={handleLogin}>
          <div className="form-control-login">
            <input 
              type="text" 
              className="input" 
              placeholder=" " 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              required 
            />
            <label>Usuario</label>
          </div>

          <div className="form-control-login">
            <input 
              type="password" 
              className="input" 
              placeholder=" " 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
            <label>Contraseña</label>
          </div>

          <button type="submit" className="btn">INGRESAR</button>

          {error && <div className="error-message">{error}</div>}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
