import React, { useState, useEffect } from 'react';
import { Typography, Container, Button, Box, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, FormControl } from "@mui/material";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import PrintIcon from '@mui/icons-material/Print';
import MainApp from './MainApp';
import './styles/PagStyle.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; 
import { useNavigate } from 'react-router-dom';

const Bisca = () => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    // Filtros de búsqueda
    const [searchName, setSearchName] = useState('');
    const [searchDoc, setSearchDoc] = useState('');
    const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://45.55.197.98:5000/api/con_bisca', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        fechaDesde: startDate,
                        fechaHasta: endDate,
                        searchName: searchName,
                        searchDoc: searchDoc
                    })
                });
    
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(errorText || 'Error en la solicitud');
                }
    
                const result = await response.json();
                setData(result);
                setFilteredData(result);
            } catch (error) {
                setError(error.message);
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [startDate, endDate, searchName,searchDoc]);
    
    

 
 
    const ExportToExcel = () => {
        const formattedData = data.map(item => ({
            ...item,
            'Fecha proceso': formatDate(item.fecha_proceso),
            'Fecha Operacion': formatDate(item.fecha_operacion),
            'Vencimiento': formatDate(item.vencimiento),
            'Ultimo Pago': formatDate(item.ultimo_pago),
            'Atraso': formatNumber(item.atraso),
            // Asegúrate de agregar todos los campos que necesitas formatear
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'Bisca.xlsx');
    };
    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        return isNaN(d.getTime()) ? '' : d.toLocaleDateString('es-ES'); // Formato DD-MM-YYYY
    };

    const formatNumber = (number) => {
        return isNaN(number) ? '' : number;
    };
    const handleExportExcel = () => {
        ExportToExcel(filteredData); // Exporta los datos filtrados
    };

    const handlePrint = (item) => {
        setSelectedItem(item);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedItem(null);
    };
    const handleExportPdf = async () => {
        if (!selectedItem) {
            console.error("No hay elemento seleccionado");
            return;
        }
    
        try {
            // Fetching the data (same as before)
            const responseOperaciones = await fetch('http://45.55.197.98:5000/api/bis_operaciones', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: selectedItem.personaid }),
            });
    
            if (!responseOperaciones.ok) throw new Error('Error al obtener datos adicionales');
            const operaciones = await responseOperaciones.json();
            const operacionesArray = Array.isArray(operaciones) ? operaciones : [operaciones];
    
            const responseCanceladas = await fetch('http://45.55.197.98:5000/api/bis_op_canceladas', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: selectedItem.personaid }),
            });
    
            if (!responseCanceladas.ok) throw new Error('Error al obtener datos de operaciones canceladas');
            const canceladas = await responseCanceladas.json();
    
            const responseDirecciones = await fetch('http://45.55.197.98:5000/api/bis_direcciones', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: selectedItem.personaid }),
            });
    
            if (!responseDirecciones.ok) throw new Error('Error al obtener detalle de direcciones');
            const direcciones = await responseDirecciones.json();
    
            const responseTarjetas = await fetch('http://45.55.197.98:5000/api/bis_tarj_cred', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: selectedItem.personaid }),
            });
    
            if (!responseTarjetas.ok) throw new Error('Error al obtener detalle de tarjetas de crédito');
            const tarjetas = await responseTarjetas.json();
    
            const responseInformes = await fetch('http://45.55.197.98:5000/api/bis_inf_cons', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: selectedItem.personaid }),
            });
    
            if (!responseInformes.ok) throw new Error('Error al obtener informes consolidados');
            const informes = await responseInformes.json();
    
            const responseScore = await fetch('http://45.55.197.98:5000/api/bis_score_his', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: selectedItem.personaid }),
            });
    
            if (!responseScore.ok) throw new Error('Error al obtener históricos del score');
            const score = await responseScore.json();
            
            // Verifica que score es un array
            const scoreArray = Array.isArray(score) ? score : [score];
    
            // Crear el documento PDF
            const doc = new jsPDF();
            doc.setFontSize(16);
            doc.text("Detalle del Archivo", 70, 25);
    
            // Datos de selectedItem
            const itemRows = Object.entries(selectedItem).map(([key, value]) => [
                key.replace(/_/g, ' ').toUpperCase(),
                String(value)
            ]);
    
            // Agregar tabla con datos de selectedItem
            doc.autoTable({
                startY: 40,
                head: [['Campo', 'Valor']],
                body: itemRows,
                theme: 'grid',
                headStyles: { fillColor: [255, 165, 0] },
            });
    
            let startY = doc.lastAutoTable.finalY + 10; // Ajusta la posición Y
            doc.setFontSize(16);
            doc.text("Históricos del Score", 70, startY);
    
            // Datos de los históricos del score
            const scoreRows = scoreArray.map(scoreData => [
                scoreData.descripcion_mes,  // Descripción del mes
                scoreData.anio,             // Año
                scoreData.mes,              // Mes
                scoreData.score             // Puntaje
            ]);
    
            // Agregar tabla de históricos del score
            doc.autoTable({
                startY: startY + 10,
                head: [['Descripción Mes', 'Año', 'Mes', 'Score']],
                body: scoreRows,
                theme: 'grid',
                headStyles: { fillColor: [255, 165, 0] }, // Color de cabecera
            });
    
            startY = doc.lastAutoTable.finalY + 10;
            doc.setFontSize(16);
            doc.text("Detalle de Operaciones", 70, startY);
    
            // Datos adicionales de operaciones
            const operacionesRows = operacionesArray.flatMap(op => [
                ['OPERACIONES ACTIVAS', String(op.operaciones_activas)],
                ['TOTAL DEUDA', String(op.total_deuda)],
                ['CONSOLIDADO CUOTAS', String(op.consolidado_cuotas)],
                ['MONTO CON ATRASO', String(op.monto_con_atraso)],
                ['ESTADO OPERACIONES', String(op.estado_operaciones)],
                ['FECHA OPERACION RECIENTE', String(op.fecha_operacion_reciente)],
                ['OPERACIONES CANCELADAS', String(op.operaciones_canceladas)],
                ['ESTADO OPERACIONES CANCELADAS', String(op.estado_operaciones_canceladas)]
            ]);
    
            // Agregar tabla con datos de operaciones adicionales
            doc.autoTable({
                startY: startY + 10,
                head: [['Campo', 'Valor']],
                body: operacionesRows,
                theme: 'grid',
                headStyles: { fillColor: [255, 165, 0] },
            });
    
            // Solo imprimir "Detalle de Operaciones Canceladas" si hay datos
            if (canceladas.length > 0) {
                startY = doc.lastAutoTable.finalY + 10; // Ajustar posición para siguiente sección
                doc.setFontSize(16);
                doc.text("Detalle de Operaciones Canceladas", 70, startY);
    
                // Obtener y agregar datos de operaciones canceladas en formato vertical
                canceladas.forEach((cancelada) => {
                    const canceladaRows = [
                        ['TIPO', cancelada.tipo],
                        ['CANTIDAD', cancelada.cantidad],
                        ['MONEDA', cancelada.moneda],
                        ['TIPO CANCELACION', cancelada.tipo_cancelacion],
                        ['CAP ORIGINAL', cancelada.cap_original],
                        ['MORA GENERADA', cancelada.mora_generada],
                        ['QUITA CAP INT CTE', cancelada.quita_cap_int_cte]
                    ];
    
                    doc.autoTable({
                        startY: doc.lastAutoTable.finalY + 10,
                        head: [['Campo', 'Valor']],
                        body: canceladaRows,
                        theme: 'grid',
                        headStyles: { fillColor: [255, 165, 0] },
                    });
                });
            }
    
            // Repite el mismo enfoque para direcciones, tarjetas, e informes
            if (direcciones.length > 0) {
                startY = doc.lastAutoTable.finalY + 10;
                doc.setFontSize(16);
                doc.text("Detalle de Direcciones", 70, startY);
    
                direcciones.forEach(direccion => {
                    const direccionRows = [
                        ['TIPO DIRECCION', direccion.tipo_direccion],
                        ['PAIS', direccion.pais],
                        ['CIUDAD', direccion.ciudad],
                        ['BARRIO', direccion.barrio],
                        ['DIRECCION', direccion.direccion],
                        ['CALLE', direccion.calle],
                        ['NRO PUERTA', direccion.nro_puerta],
                        ['NRO APARTAMENTO', direccion.nro_apartamento],
                        ['EDIFICIO', direccion.edificio],
                        ['TELEFONO', direccion.telefono],
                        ['COD POSTAL', direccion.cod_postal],
                        ['FECHA REGISTRADA', direccion.fecha_registrada],
                        ['FECHA INFORMADO', direccion.fecha_informado]
                    ];
    
                    doc.autoTable({
                        startY: doc.lastAutoTable.finalY + 10,
                        head: [['Campo', 'Valor']],
                        body: direccionRows,
                        theme: 'grid',
                        headStyles: { fillColor: [255, 165, 0] },
                    });
                });
            }
    
            if (tarjetas.length > 0) {
                startY = doc.lastAutoTable.finalY + 10;
                doc.setFontSize(16);
                doc.text("Detalle de Tarjetas de Crédito", 70, startY);
    
                tarjetas.forEach(tarjeta => {
                    const tarjetaRows = [
                        ['TARJETAS CREDITO', tarjeta.tarjetas_credito],
                        ['LINEA TOTAL', tarjeta.linea_total],
                        ['LINEA UTILIZADA', tarjeta.linea_utilizada],
                        ['MONTO CON ATRASO', tarjeta.monto_con_atraso],
                        ['ESTADO OPERACIONES', tarjeta.estado_operaciones]
                    ];
    
                    doc.autoTable({
                        startY: doc.lastAutoTable.finalY + 10,
                        head: [['Campo', 'Valor']],
                        body: tarjetaRows,
                        theme: 'grid',
                        headStyles: { fillColor: [255, 165, 0] },
                    });
                });
            }
    
            if (informes.length > 0) {
                startY = doc.lastAutoTable.finalY + 10;
                doc.setFontSize(16);
                doc.text("Detalle de Informes Consolidados", 70, startY);
    
                informes.forEach(informe => {
                    const informeRows = [
                        ['FECHA CONSOLIDADO', informe.fecha_consolidado],
                        ['DEUDA TOTAL', informe.deuda_total],
                        ['MONTO ATRASO', informe.monto_atraso],
                        ['CANTIDAD ENTIDADES', informe.cantidad_entidades],
                        ['ESTADO', informe.estado]
                    ];
    
                    doc.autoTable({
                        startY: doc.lastAutoTable.finalY + 10,
                        head: [['Campo', 'Valor']],
                        body: informeRows,
                        theme: 'grid',
                        headStyles: { fillColor: [255, 165, 0] },
                    });
                });
            }
    
            // Guardar el PDF
            doc.save(`${selectedItem.nombre_completo}_Detalle_Archivo.pdf`);
    
        } catch (error) {
            console.error("Error al generar el PDF:", error);
        }
    };
    
    
    

    const handleConsultardocumento = () => {
        navigate('/consultardocumento');
    };
    
    return (
        <MainApp>
            <Box className="gradient-background">
                <Container className="container">
                    <Typography variant="h4" component="h1" gutterBottom className="title">
                        Consulta de archivos Bicsa
                        
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom className="title">
                        Filtros de búsqueda
                    </Typography>
                    <Box className="search-container">
                    <Box sx={{ display: 'flex', gap: 0.5, mb: 2, width: '150vh' }}>
                        <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Número de Documento"
                                    variant="outlined"
                                    value={searchDoc}
                                    onChange={(e) => setSearchDoc(e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Nombre Completo"
                                    variant="outlined"
                                    value={searchName}
                                    onChange={(e) => setSearchName(e.target.value)}
                                />
                            </FormControl>
                        </Box>
                        <Box className="search-filters" sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                            <TextField label="Desde" type="date" variant="outlined" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} InputLabelProps={{ shrink: true }} sx={{ flex: 1 }} />
                            <TextField label="Hasta" type="date" variant="outlined" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} InputLabelProps={{ shrink: true }} sx={{ flex: 1 }} />
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConsultardocumento}
                            sx={{ mt: 2 }}
                        >
                            Consultar Documento
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#4caf50', color: '#ffffff', marginTop: 16 }}
                            onClick={handleExportExcel}
                        >
                            Descargar Excel
                        </Button>
                    </Box>
                    {loading ? (
                        <Typography variant="h6">Cargando datos...</Typography>
                    ) : error ? (
                        <Typography variant="h6" color="error">Error: {error}</Typography>
                    ) : (
                        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-header-cell">Acciones</TableCell>
                                        <TableCell className="table-header-cell">Documento</TableCell>
                                        <TableCell className="table-header-cell">Nombre Completo</TableCell>
                                        <TableCell className="table-header-cell">Nacionalidad</TableCell>
                                        <TableCell className="table-header-cell">Tipo Persona</TableCell>
                                        <TableCell className="table-header-cell">Nacimiento</TableCell>
                                        <TableCell className="table-header-cell">Sector Económico</TableCell>
                                        <TableCell className="table-header-cell">Origen</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData && filteredData.length > 0 ? (
                                        filteredData.map((item, index) => (
                                            <TableRow key={index} className="styled-table-row">
                                                <TableCell className="table-cell">
                                                    <IconButton onClick={() => handlePrint(item)} color="primary">
                                                        <PrintIcon />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell className="table-cell">{item.nro_documento}</TableCell>
                                                <TableCell className="table-cell">{item.nombre_completo}</TableCell>
                                                <TableCell className="table-cell">{item.nacionalidad}</TableCell>
                                                <TableCell className="table-cell">{item.tipo_persona}</TableCell>
                                                <TableCell className="table-cell">{new Date(item.fecha_nacimiento).toLocaleDateString()}</TableCell>
                                                <TableCell className="table-cell">{item.sector_economico}</TableCell>
                                                <TableCell className="table-cell">{item.origen}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={8} className="table-cell">No hay datos para mostrar</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

 

                    {selectedItem && (
                        <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                            <DialogTitle>Detalle del Registro</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Aquí están todos los detalles del registro seleccionado:
                                </DialogContentText>
                                <Box>
                                    {Object.entries(selectedItem).map(([key, value]) => (
                                        <Typography key={key} variant="body1">
                                            <strong>{key.replace(/_/g, ' ').toUpperCase()}:</strong> {String(value)}
                                        </Typography>
                                    ))}
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    Cerrar
                                </Button>
                                <Button onClick={handleExportPdf} color="secondary">
                                    Descargar PDF
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Container>
            </Box>
        </MainApp>
    );
};

export default Bisca;