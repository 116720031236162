import React, { useState, useEffect } from 'react';
import { Typography, Container, Button, Box, Snackbar, Alert, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, FormControl } from "@mui/material";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import PrintIcon from '@mui/icons-material/Print';
import MainApp from './MainApp';
import './styles/PagStyle.css';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; 

const Equifax = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [filePath, setFilePath] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedItem, setSelectedItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    // Filtros de búsqueda
    const [searchName, setSearchName] = useState('');
    const [searchLastName, setSearchLastName] = useState('');
    const [searchDoc, setSearchDoc] = useState('');
    const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
    const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('http://45.55.197.98:5000/api/con_equifax', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        fechaDesde: startDate,
                        fechaHasta: endDate,
                        searchName: searchName,
                        searchLastName: searchLastName,
                        searchDoc: searchDoc
                    })
                });
    
                if (!response.ok) {
                    throw new Error('Error en la solicitud');
                }
    
                const result = await response.json();
                setData(result);
                setFilteredData(result);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
    
        fetchData();
    }, [startDate, endDate, searchName,searchLastName,searchDoc]);
    

 
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('http://45.55.197.98:5000/upload', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const result = await response.text();
                const path = result.replace('Archivo subido exitosamente: ', '');
                setFilePath(path);
                setSnackbarMessage('Archivo subido correctamente');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } else {
                const errorText = await response.text();
                setSnackbarMessage(`Error al subir el archivo: ${errorText}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Error al subir el archivo');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };



    const handleReadPdf = async () => {
        if (!filePath) return;

        try {
            const response = await fetch('http://45.55.197.98:5000/lee-pdf-equifax', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ filePath })
            });

            if (response.ok) {
                const data = await response.json();
                setData(data);
                setSnackbarMessage('PDF leído exitosamente');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);

                //window.location.reload(); // Recarga la página para actualizar los datos
            } else {
                const errorText = await response.text();
                setSnackbarMessage(`Error al leer el PDF: ${errorText}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setSnackbarMessage('Error al leer el PDF');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const ExportToExcel = () => {
        const formattedData = data.map(item => ({
            ...item,
            'Fecha proceso': formatDate(item.fecha_proceso),
            'Fecha Operacion': formatDate(item.fecha_operacion),
            'Vencimiento': formatDate(item.vencimiento),
            'Ultimo Pago': formatDate(item.ultimo_pago),
            'Atraso': formatNumber(item.atraso),
        }));
        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'Equifax.xlsx');
    };
    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        return isNaN(d.getTime()) ? '' : d.toLocaleDateString('es-ES'); // Formato DD-MM-YYYY
    };

    const formatNumber = (number) => {
        return isNaN(number) ? '' : number;
    };
    const handleExportExcel = () => {
        ExportToExcel(filteredData); // Exporta los datos filtrados
    };

    const handlePrint = (item) => {
        setSelectedItem(item);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedItem(null);
    };
    const handleExportPdf = async () => {
        if (!selectedItem) return;
    
        try {
            const response = await fetch('http://45.55.197.98:5000/api/con_equifax_det', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ equifax_id: selectedItem.id })
            });
    
            if (response.status === 404) {
                console.log('No se encontraron detalles de consultas para el equifax_id proporcionado');
            } else if (!response.ok) {
                throw new Error('Error al obtener los detalles de consultas');
            }
    
            const detallesConsultas = response.status === 404 ? [] : await response.json();
    
            const doc = new jsPDF();
            let currentY = 20;
    
            doc.setFontSize(16);
            doc.text("Detalle del Registro", 14, currentY);
            currentY += 10;
    
            const rows = Object.entries(selectedItem)
                .filter(([key]) => !['id', 'equifax_id', 'detalle consultas'].includes(key.toLowerCase()))
                .map(([key, value]) => [
                    key.replace(/_/g, ' ').toUpperCase(),
                    String(value)
                ]);
    
            doc.autoTable({
                startY: currentY,
                head: [['Campo', 'Valor']],
                body: rows,
                theme: 'grid',
                headStyles: { fillColor: [255, 165, 0] }
            });
    
            currentY = doc.lastAutoTable.finalY + 10;
    
            if (detallesConsultas.length > 0) {
                doc.setFontSize(14);
                doc.text("Detalles de Consultas", 14, currentY);
                currentY += 10;
    
                // Map the details to match the new column format and format the dates
                const consultaRows = detallesConsultas.map((consulta) => {
                    const consultaFecha = new Date(consulta.consulta_fecha).toLocaleDateString('en-CA'); // Formats date as YYYY-MM-DD
                    return {
                        consulta_fecha: consultaFecha,
                        entidad: consulta.entidad || "N/A",
                        referencia: consulta.referencia || "N/A"
                    };
                });
    
                doc.autoTable({
                    startY: currentY,
                    head: [['CONSULTA FECHA', 'ENTIDAD', 'REFERENCIA']],
                    body: consultaRows.map(({ consulta_fecha, entidad, referencia }) => [consulta_fecha, entidad, referencia]),
                    theme: 'grid',
                    headStyles: { fillColor: [255, 165, 0] }
                });
    
                currentY = doc.lastAutoTable.finalY + 10;
            } else {
                doc.text("No hay detalles de consultas disponibles.", 14, currentY);
            }
    
            doc.save(`Detalle_Registro_${selectedItem.documento}.pdf`);
        } catch (error) {
            console.error('Error al generar el PDF:', error);
            setSnackbarMessage('Error al generar el PDF');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };
    
    

    return (
        <MainApp>
            <Box className="gradient-background">
                <Container className="container">
                    <Typography variant="h4" component="h1" gutterBottom className="title">
                        Consulta de archivos Equifax
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom className="title">
                        Filtros de búsqueda
                    </Typography>
                    <Box className="search-container">
                        <Box sx={{ display: 'flex', gap: 0.5, mb: 2, width: '150vh' }}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Nombre"
                                    variant="outlined"
                                    value={searchName}
                                    onChange={(e) => setSearchName(e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Apellido"
                                    variant="outlined"
                                    value={searchLastName}
                                    onChange={(e) => setSearchLastName(e.target.value)}
                                />
                            </FormControl>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    label="Número de Documento"
                                    variant="outlined"
                                    value={searchDoc}
                                    onChange={(e) => setSearchDoc(e.target.value)}
                                />
                            </FormControl>

                        </Box>
                        <Box className="search-filters" sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                            <TextField label="Desde" type="date" variant="outlined" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} InputLabelProps={{ shrink: true }} sx={{ flex: 1 }} />
                            <TextField label="Hasta" type="date" variant="outlined" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} InputLabelProps={{ shrink: true }} sx={{ flex: 1 }} />

                        </Box>
                        <input
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="file-input"
                        />
                        <label htmlFor="file-input">
                            <Button variant="contained" color="primary" component="span">
                                Seleccionar Archivo
                            </Button>
                        </label>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleFileUpload}
                            disabled={!file}
                        >
                            Levantar Archivo
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReadPdf}
                            disabled={!filePath}
                        >
                            Leer Archivo
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#4caf50', color: '#ffffff', marginTop: 16 }}
                            onClick={handleExportExcel}
                        >
                            Descargar Excel
                        </Button>
                    </Box>
                    {loading ? (
                        <Typography variant="h6">Cargando datos...</Typography>
                    ) : error ? (
                        <Typography variant="h6" color="error">Error: {error}</Typography>
                    ) : (
                        <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="table-header-cell">Acciones</TableCell>
                                        <TableCell className="table-header-cell">Documento</TableCell>
                                        <TableCell className="table-header-cell">Tipo de Documento</TableCell>
                                        <TableCell className="table-header-cell">Nombre</TableCell>
                                        <TableCell className="table-header-cell">Apellido</TableCell>
                                        <TableCell className="table-header-cell">Fecha de Nacimiento</TableCell>
                                        <TableCell className="table-header-cell">Edad</TableCell>
                                        <TableCell className="table-header-cell">Nacionalidad</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData && filteredData.length > 0 ? (
                                        filteredData.map((item, index) => (
                                            <TableRow key={index} className="styled-table-row">
                                                <TableCell className="table-cell">
                                                    <IconButton onClick={() => handlePrint(item)} color="primary">
                                                        <PrintIcon />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell className="table-cell">{item.documento}</TableCell>
                                                <TableCell className="table-cell">{item.tipo_documento}</TableCell>
                                                <TableCell className="table-cell">{item.nombre}</TableCell>
                                                <TableCell className="table-cell">{item.apellido}</TableCell>
                                                <TableCell className="table-cell">{new Date(item.fecha_nacimiento).toLocaleDateString()}</TableCell>
                                                <TableCell className="table-cell">{item.edad}</TableCell>
                                                <TableCell className="table-cell">{item.nacionalidad}</TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={8} className="table-cell">No hay datos para mostrar</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>

                    {selectedItem && (
                        <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                            <DialogTitle>Detalle del Registro</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Aquí están todos los detalles del registro seleccionado:
                                </DialogContentText>
                                <Box>
                                    {Object.entries(selectedItem).map(([key, value]) => (
                                        <Typography key={key} variant="body1">
                                            <strong>{key.replace(/_/g, ' ').toUpperCase()}:</strong> {String(value)}
                                        </Typography>
                                    ))}
                                </Box>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDialog} color="primary">
                                    Cerrar
                                </Button>
                                <Button onClick={handleExportPdf} color="secondary">
                                    Descargar PDF
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Container>
            </Box>
        </MainApp>
    );
};

export default Equifax;
